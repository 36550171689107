<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Product Items/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">

          <div class="card-body">
            <!--         start basic info section  -->
            <div>
              <h5 class="my-1 text-uppercase">Basic Info</h5>
              <div class="row">
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <div class="controls">
                      <label>Select Product</label>
                      <VueMultiselect :allow-empty="false" v-model="selectedProduct" class="multiselect-blue"
                                      :options="productOptions"
                                      :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                      :show-labels="false"/>
                    </div>
                    <div class="text-danger" v-if="errors.product_id">{{ errors.product_id }}</div>
                  </div>

                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <div class="">
                      <label>Serial Number</label>
                      <input v-model="postProductItemData.serial_number" type="text" class="form-control"
                             placeholder="Enter Serial Number">
                    </div>
                  </div>

                </div>

                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <div class="controls">
                      <label>Model Number</label>
                      <input v-model="postProductItemData.model_number" type="text" class="form-control"
                             placeholder="Enter Product Code">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <div class="controls">
                      <label>Select Warehouse</label>
                      <VueMultiselect :allow-empty="false" v-model="selectedWarehouse" class="multiselect-blue"
                                      :options="warehouseOptions"
                                      :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                      :show-labels="false"/>
                    </div>
                    <div class="text-danger" v-if="errors.warehouse_id">{{ errors.warehouse_id }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--         end basic info section   -->
            <!--         start type info section   -->
            <div>
              <h5 class="my-1 text-uppercase"> Code Info </h5>
              <div class="row">
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <div class="">
                      <label>SKU</label>
                      <input v-model="postProductItemData.sku" type="text" class="form-control">

                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <div class="">
                      <label>BarCode</label>
                      <input v-model="postProductItemData.barcode" type="text" class="form-control">
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <div class="">
                      <label>QR Code</label>
                      <input v-model="postProductItemData.qr_code" type="text" class="form-control"
                             placeholder="Enter Product Code">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <div class="controls">
                      <label>Manufacturing Code</label>
                      <input v-model="postProductItemData.manufacturing_code" type="text" class="form-control"
                             placeholder="Enter Product Code">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--         end type info section   -->
            <!--            start other info section   -->
            <div>
              <h5 class="my-1 text-uppercase"> Others Info </h5>
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <div class="">
                      <label>Purchase Cost</label>
                      <input v-model="postProductItemData.purchase_cost" type="number" class="form-control">

                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <div class="controls">
                      <label>Status</label>
                      <VueMultiselect :allow-empty="false" v-model="selectedCondition" class="multiselect-blue"
                                      :options="conditionOptions"
                                      :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                      :show-labels="false"/>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <div class="controls">
                      <label>Select State</label>
                      <VueMultiselect v-model="selectedState" class="multiselect-blue" :options="stateOptions"
                                      :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                      :show-labels="false"/>
                    </div>
                    <div class="text-danger" v-if="errors.state">{{ errors.state }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--            end other info section   -->
            <!--            start licence info section   -->
            <div>
              <h5 class="my-1 text-uppercase"> Licence Info </h5>
              <div class="row">
                <div class="col-12 col-md-2">
                  <div class="form-group">
                    <fieldset class="mt-2">
                      <div class="checkbox checkbox-primary">
                        <input type="checkbox" class="" id="hasLicence" v-model="hasLicence">
                        <label for="hasLicence">Has Licence</label>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div v-if="hasLicence" class="col-md-10 d-flex">
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <div class="controls">
                        <label>Licence Type</label>
                        <VueMultiselect :allow-empty="false" v-model="selectedLicenceType" class="multiselect-blue"
                                        :options="licenceTypeOptions"
                                        :close-on-select="true" placeholder="Select Licence type" label="name"
                                        track-by="name"
                                        :show-labels="false"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <div class="controls">
                        <label>Period</label>
                        <VueMultiselect v-model="selectedPeriod" :allow-empty="false" class="multiselect-blue" :options="periodOptions"
                                        :close-on-select="true" placeholder="Select Period" label="name" track-by="name"
                                        :show-labels="false"/>
                      </div>

                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <div class="">
                        <label>Licence Number</label>
                        <input v-model="postProductLicenseData.license_key" type="text" class="form-control"
                               placeholder="Enter licence Number">
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
            <!--            end licence info section-->
            <!--            start vendor info section   -->
            <div>
              <h5 class="my-1 text-uppercase"> Vendor Info </h5>
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <div class="controls">
                      <label>Vendor List</label>
                      <VueMultiselect :allow-empty="false" v-model="selectedVendor" class="multiselect-blue"
                                      :options="vendorOptions"
                                      :close-on-select="true" placeholder="Select Vendor" label="name" track-by="name"
                                      :show-labels="false"/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!--            end vendor info section   -->
            <div class="row pb-4">
              <div class="col-12 ">
                <div class="d-flex justify-content-end">
                  <button @click="singlePostProductItem" type="button" class="btn btn-primary update-todo px-4">Add
                    Product Item
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/backEnd/AppLayout";
import VueMultiselect from "vue-multiselect";
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "CreateProductItem",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    VueMultiselect,
  },
  data() {
    return {
      hasLicence: false,
      licence_number: '',
      selectedVendor: {
        value: '',
        name: 'None'
      },
      selectedLicenceType: {
        value: '',
        name: 'None'
      },
      selectedPeriod: {
        value: '',
        name: 'None'
      },
      licenceTypeOptions: [
        {
          value: '',
          name: 'None'
        },
        {
          value: 0,
          name: 'Subscription'
        },
        {
          value: 1,
          name: 'Period'
        }
      ],
      periodOptions: [
        {
          value: '',
          name: 'None'
        },
        {
          value: '+7 days',
          name: '7 Days'
        },
        {
          value: '+15 days',
          name: '15 Days'
        },
        {
          value: '+30 days',
          name: '30 days'
        },
        {
          value: '+3 months',
          name: '3 months'
        },
        {
          value: '+6 months',
          name: '6 months'
        },
        {
          value: '+1 year',
          name: '1 year'
        },
        {
          value: '+2 years',
          name: '2 years'
        },
        {
          value: '+3 years',
          name: '3 years'
        },
      ],
      postProductItemData: {
        product_id: '',
        warehouse_id: '',
        vendor_id: '',
        serial_number: '',
        model_number: '',
        sku: '',
        barcode: '',
        qr_code: '',
        manufacturing_code: '',
        purchase_cost: '',
        condition: '',
        state: '',
      },
      selectedProduct: {
        value: '',
        name: 'None'
      },
      selectedCondition: {
        value: '',
        name: 'None'
      },
      getSettingsParams: {
        type: ['product_item', 'default'],
        key: ['product_item_condition', 'default_state'],
      },
      selectedWarehouse: {
        value: '',
        name: 'None'
      },
      selectedState: {
        value: '',
        name: 'None'
      },
      errors: {
        product_id: '',
        serial_number: '',
        model_number: '',
        sku: '',
        barcode: '',
        qr_code: '',
        manufacturing_code: '',
        purchase_cost: '',
        condition: '',
        warehouse_id: '',
        state: '',
      },
      productItem: {},
      getVendorsParams: {
        status: 1,
        with_relation: [
          'user',
          'address'
        ]
      },
      getWarehousesParams: {
        status: 1,
        with_relation: ['address','managedBy']
      },
      getProductsParams: {
        status: 1,

      },
      postProductLicenseData: {
        user_id: '',
        product_item_id: '',
        license_type: '',
        period: '',
        license_key: '',
      },
      selectedWarehouseManager: ''

    }
  },
  watch: {
    selectedCondition() {
      this.postProductItemData.condition = this.selectedCondition.value;
    },
    selectedProduct() {
      this.postProductItemData.product_id = this.selectedProduct.value;
    },
    selectedWarehouse() {
      this.postProductItemData.warehouse_id = this.selectedWarehouse.value;
      // check selected warehouse manager available or not
      this.selectedWarehouseManager = this.$store.getters['appWarehouses/warehouses'].find(({id}) => id === this.selectedWarehouse.value).managedBy;
      if(this.selectedWarehouseManager === null){
        this.showToastMessage({message: 'No store manager in the warehouse, please add .', type: 'error'})
      }
    },
    selectedState() {
      this.postProductItemData.state = this.selectedState.value;
    },
    selectedLicenceType() {
      this.postProductLicenseData.license_type = this.selectedLicenceType.value;
    },
    selectedPeriod() {
      this.postProductLicenseData.period = this.selectedPeriod.value;
    },
    selectedVendor() {
      this.postProductItemData.vendor_id = this.selectedVendor.value;
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      authUser: 'appAuthLogin/authUser',

    }),
    productOptions() {
      let products = this.$store.getters['appProducts/products'].map((type) => {
        let id = type.id;
        let name = type.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...products];
    },
    conditionOptions() {
      let condition = this.$store.getters['appSettings/settingProductItemCondition'].value.map((item) => {
        let value = item.value;
        let name = item.name;
        return {value: value, name: name};
      });
      return [{value: '', name: 'None'}, ...condition];
    },
    stateOptions() {
      let states = this.$store.getters['appSettings/settingDefaultState'].value;
      return [{value: '', name: 'None'}, ...states];
    },
    warehouseOptions() {
      let warehouses = this.$store.getters['appWarehouses/warehouses'].map((warehouse) => {
        let id = warehouse.id, name = warehouse.name;
        return {value: id, name: name};
      })
      return [{value: '', name: 'None'}, ...warehouses]
    },
    vendorOptions() {
      let vendors = this.$store.getters['appVendors/vendors'].map((vendor) => {
        let id = vendor.id, first_name = vendor?.user?.first_name ?? '', last_name = vendor?.user?.last_name ?? '',
            code = vendor.code;
        return {value: id, name: `${first_name} ${last_name} -code: ${code}`};
      })
      return [{value: '', name: 'None'}, ...vendors]
    },
    isProductLicensesDataFilled() {
      return !!(this.hasLicence && this.postProductLicenseData.license_type !== null && this.postProductLicenseData.period && this.postProductLicenseData.license_key);
    },
    redirectRouteName() {
      return this.can('product-item-view-any') ? 'appProductItemList' : 'appProductItemCreate';
    },
  },
  methods: {
    ...mapActions({
      getProducts: 'appProducts/getProducts',
      postProductItem: 'appProductItems/postProductItem',
      getSettings: 'appSettings/getSettings',
      getWarehouses: 'appWarehouses/getWarehouses',
      postProductItemTracking: 'appProductItemTracking/postProductItemTracking',
      getVendors: 'appVendors/getVendors',
      postProductLicense: 'appProductLicenses/postProductLicense',
    }),
    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
    async singlePostProductItem() {
      await this.postProductItem({
        ...this.postProductItemData,
        purchase_cost: this.postProductItemData.purchase_cost * 100,
      }).then(async (response) => {
        if (response.status === 201) {
          this.productItem = this.$store.getters['appProductItems/productItem'];
          // create item tracking
          try {
            await this.createProductItemTrackings();

            // create product license
            if (this.isProductLicensesDataFilled) {
              this.postProductLicenseData.product_item_id = this.productItem.id;
              await this.createProductItemLicenses(this.postProductLicenseData)
            }

          } catch (e) {
            console.log(e.message)
          }

          const toastObj = {message: 'Product Item created successful.', type: 'success'};
          this.showToastMessage(toastObj);

          await this.$router.push({
            name: this.redirectRouteName,
          });
          return;
        }
        this.errors.product_id = response?.errors?.product_id?.[0] ?? '';
        this.errors.state = response?.errors?.state?.[0] ?? '';
        this.errors.warehouse_id = response?.errors?.warehouse_id?.[0] ?? '';
        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },

    async createProductItemTrackings() {
      const dataObj = {
        user_id: this.authUser.id,
        product_item_id: this.productItem.id,
        action: 0 // 0 for issued
      };
      this.loader(true);
      return await this.postProductItemTracking(dataObj).then((response) => {
        if (response.status === 201) {
          this.loader(false);
          return true;
        } else {
          this.loader(false);
          return false;
        }
      });
    },

    async createProductItemLicenses(dataObj) {
      this.loader(true);
      return await this.postProductLicense(dataObj).then((response) => {
        if (response.status === 201) {
          this.loader(false);
          return true;
        } else {
          this.loader(false);
          return false;
        }
      });
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList(this.getSettingsParams);
    await this.getProducts(this.getProductsParams);
    await this.getWarehouses(this.getWarehousesParams);
    await this.getVendors(this.getVendorsParams);
    await this.loader(false);
  }
}
</script>

<style scoped>

</style>